<template>
  <div class="card-cart">
    <h3 class="card-title">
      {{ $t('components.card_cart.title') }}
    </h3>
    <div
      v-if="hasItems"
      class="content-offereds"
    >
      <card-offered-item
        v-for="(item, index) in cartItems"
        :key="item.id"
        :item="item"
        :index="index"
        @deleteOfferedItem="deleteOfferedItem"
      />
    </div>
    <div
      v-else
      class="content-empty"
    >
      <span class="text-empty">
        {{ $t('components.card_cart.empty') }}
      </span>
    </div>
  </div>
</template>
<script>
import CardOfferedItem from '@/components/cards/cart/CardOfferedItem.vue';

export default {
  name: 'CardCart',
  components: { CardOfferedItem },
  props: {
    cartItems: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasItems() {
      return this.cartItems.length > 0;
    },
  },
  methods: {
    deleteOfferedItem(item) {
      this.$emit('deleteOfferedItem', item);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-cart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $card_cart;
  border-radius: 10px;
  padding: 38px;

  .card-title {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .content-empty {
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
  }
}
</style>
