import { FormatMoney } from 'format-money-js';

const fm = new FormatMoney({
  decimals: 2,
  separator: '.',
  decimalPoint: ',',
});

export default {
  methods: {
    formatMoney(value) {
      return fm.from(Number(value), { symbol: 'R$ ' });
    },
  },
};
