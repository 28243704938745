<template>
  <v-btn
    :to="redirect"
    :width="width"
    :disabled="disabled"
    class="rectoplus-button"
    :color="color"
    :elevation="elevation"
    :outlined="outlined"
    @click="onClick"
  >
    {{ text }}
  </v-btn>
</template>
<script>
export default {
  props: {
    redirect: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: null,
    },
    elevation: {
      type: [Number, String],
      default: 0,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss">
  .rectoplus-button.v-btn:not(.v-btn--round).v-size--default {
    $btn-text-transform: normal;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    font-size: 1.125em;

    .v-btn__content {
      text-transform: none;
      letter-spacing: 0;
      font-size: inherit;
      font-weight: 600;
    }
  }
</style>
