<template>
  <div class="card-offered-item">
    <v-btn
      class="button-delete-offered"
      icon
      @click="deleteOfferedItem()"
    >
      <v-icon color="ic_invalid">
        mdi-delete-outline
      </v-icon>
    </v-btn>

    <card-info
      :item="item.credit_offered"
    />
    <div class="card-actions">
      <div class="card-content-actions text-right mr-8 d-flex flex-column justify-space-around">
        <div class="text-unit-value">
          {{ $t('components.card_cart.card_offered_item.unit_value') }}
        </div>
        <div class="text-unit-value">
          {{ $t('components.card_cart.card_offered_item.central_custody_fee_value') }}
        </div>
        <div class="text-total-value">
          {{ $t('components.card_cart.card_offered_item.total_value') }}
        </div>
      </div>

      <div class="card-content-actions d-flex flex-column justify-space-around">
        <div class="font-weight-bold">
          {{ formatMoney(item.credit_offered.unit_value_with_aditional) }}
        </div>
        <div class="font-weight-bold">
          {{ formatMoney(item.credit_offered.central_custody_fee_value) }}
        </div>
        <div class="font-weight-bold total-value">
          {{ formatMoney(totalValue) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import formatMoney from '@/mixins/formatMoney';
import CardInfo from '@/components/cards/offered/CardInfo.vue';

export default {
  name: 'CardOfferedItem',
  components: {
    CardInfo,
  },
  mixins: [formatMoney],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(['getBusiness']),

    totalValue() {
      return this.item.total_value;
    },
  },

  methods: {
    deleteOfferedItem() {
      this.$emit('deleteOfferedItem', { index: this.index, id: this.item.id });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-offered-item {
  display: flex;
  justify-content: space-between;
  background: $card_offer;
  border-radius: 10px;
  padding: 20px;
  box-shadow: $box_shadow_cart_item;
  margin-bottom: 20px;
  position: relative;

  .button-delete-offered {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .card-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card-content-actions {
      height: 200px;

      .text-quantity,
      .text-unit-value {
        font-size: 0.875em;
        color: $color_gray;
      }

      .text-quantity {
        height: 36px;
      }

      .text-total-value {
        font-size: 1em;
        color: $color_gray;
      }

      .total-value {
        font-size: 1.4em;
      }

      .field-quantity {
        background: $dashboard_background;
        width: 100%;
        max-width: 150px;
        border-radius: 10px;
        padding: 4px;
        text-align: center;
        outline: none;
      }
    }
  }
  .content-error {
    height: 10px;

    .error-message {
      color: $message_invalid;
      font-size: 0.8em;
      margin-top: 4px;
    }
  }
}
</style>
