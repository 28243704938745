<template>
  <div
    class="button-back"
    @click="goTo()"
  >
    <img
      src="@/assets/icons/ic_back.svg"
      class="img-back"
    >
    <div class="text-back">
      {{ text }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'BackButton',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  methods: {
    goTo() {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
.button-back {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  cursor: pointer;

  .text-back {
    margin-left: 10px;
    color: $color_primary;
    font-size: 1em;
    font-weight: bold;
  }

  .img-back {
    width: 16px;
  }
}
</style>
