<template>
  <div class="cart">
    <div class="card-cart">
      <div class="cart-title">
        <back-button
          :text="$t('views.cart.back')"
          @click="goToHome()"
        />
        <span class="page">{{ $t('views.cart.title') }}</span>
      </div>
      <card-cart
        :cart-items="cartItems"
        @deleteOfferedItem="deleteOfferedItem"
      />
    </div>
    <div class="card-total">
      <span class="text-total">{{ $t('views.cart.total') }}</span>
      <span class="text-value">{{ formatMoney(totalValue) }}</span>
    </div>
    <div class="content-button">
      <rectoplus-button
        class="button-next"
        elevation="0"
        :text="$t('views.cart.buttons.next')"
        @click="goToCheckout()"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BackButton from '@/components/buttons/BackButton.vue';
import CardCart from '@/components/cards/cart/CardCart.vue';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';
import formatMoney from '@/mixins/formatMoney';

export default {
  name: 'Cart',
  components: {
    BackButton,
    CardCart,
    RectoplusButton,
  },

  mixins: [formatMoney],

  data() {
    return {
      total: 0,
    };
  },

  computed: {
    ...mapGetters({
      cartItems: 'getCartItems',
    }),
    totalValue() {
      const quantities = this.cartItems.map((item) => Number(item.total_value));
      if (quantities.length <= 0) return 0;
      return quantities.reduce((previousValue, currentValue) => previousValue + currentValue);
    },
  },

  mounted() {
    this.fetchCartItems();
  },

  methods: {
    ...mapActions([
      'fetchCartItems',
      'deleteCartItem',
      'updateCartItem',
      'initLoading',
    ]),

    deleteOfferedItem(item) {
      this.initLoading();
      this.deleteCartItem(item);
    },

    goToCheckout() {
      this.fetchCartItems();
      this.$router.push({ name: 'checkout' });
    },

    editOfferedItem(item) {
      this.initLoading();
      this.updateCartItem(item);
    },

    validateOffered(errors) {
      this.errors = [...errors];
    },

    goToHome() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>
<style lang="scss" scoped>
.cart {
  background: $dashboard_background;
  min-height: 100vh;
  display: flex;
  padding: 2em 2em 2em 16em;
  flex-direction: column;

  .card-cart {
    .cart-title {
      display: flex;
      width: 60%;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 50px;

      .page {
        font-size: 1.5em;
        font-weight: bold;
      }

      .content-cart-button {
        display: flex;
        cursor: pointer;

        .cart-icon {
          display: flex;
          flex-direction: column;
          position: relative;

          .number-cart-itens {
            color: $color_primary;
            font-size: 0.875em;
            font-weight: bold;
            position: absolute;
            bottom: 16px;
            right: 8px;
          }
        }

        .text-cart {
          color: $color_primary;
          font-weight: bold;
        }
      }
    }
  }

  .card-total {
    background-color: $card_cart;
    margin: 20px 0px;
    padding: 30px;
    border-radius: 10px;
    text-align: end;

    .text-total {
      font-size: 1.5em;
      font-weight: bold;
      margin-right: 16px;
      color: $color_gray;
    }

    .text-value {
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  .content-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .button-next {
      width: 100%;
      max-width: 300px;
      height: 56px;
    }
  }
}
</style>
