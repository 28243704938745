<template>
  <div class="card-infos mr-8">
    <div class="d-flex justify-space-between">
      <component
        :is="getIconByMaterialType(mountMaterial(item.material_type), true)"
        class="material-type-img mr-4"
      />
      <div class="info-offer">
        <div v-if="!hideOperator">
          <span class="font-weight-bold mr-2">
            {{ mountTranslate('operator_type') }}
          </span>
          <span class="offered-value">
            {{ mountTranslate(`business_kind.${item.business_kind}`) }}
          </span>
        </div>
        <div v-if="!hideOperator">
          <span class="font-weight-bold mr-2">
            {{ mountTranslate('state') }}
          </span>
          <span class="offered-value">
            {{ item.business_state }}
          </span>
        </div>
        <div>
          <span class="font-weight-bold mr-2">
            {{ mountTranslate('available_for_purchase') }}
          </span>
          <span class="offered-value">
            {{ item.created_at }}
          </span>
        </div>
        <div>
          <span class="font-weight-bold mr-2">
            {{ mountTranslate('material_type') }}
          </span>
          <span class="offered-value">
            {{ item.material_type }}
          </span>
        </div>
        <div>
          <span class="font-weight-bold mr-2">
            {{ mountTranslate('mass_quantity') }}
          </span>
          <span class="offered-value">
            {{ mountTranslate('mass_quantity_value', item.mass_quantity.toLocaleString('pt-BR') ) }}
          </span>
        </div>
        <div>
          <span class="font-weight-bold mr-2">
            {{ mountTranslate('total_offer') }}
          </span>
          <span class="offered-value">
            {{ mountTranslate('total_offer_value', item.credits_quantity.toLocaleString('pt-BR')) }}
          </span>
        </div>
        <div v-if="showYear">
          <span class="font-weight-bold mr-2">
            {{ mountTranslate('year_nfes') }}
          </span>
          <span class="offered-value">
            {{ yearNfes }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getIconsByMaterialType from '@/mixins/getIconsOfMaterialType';

export default {
  name: 'CardInfo',
  mixins: [getIconsByMaterialType],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    hideOperator: {
      type: Boolean,
      default: false,
    },
    showYear: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    yearNfes() {
      return this.item.year_nfes.join(', ');
    },
  },

  methods: {
    mountTranslate(path, value) {
      return this.$t(`components.offered.card_offered.${path}`, {
        value,
      });
    },

    mountMaterial(material) {
      if (material === 'Papel') return 'paper';
      if (material === 'Plástico') return 'plastic';
      if (material === 'Vidro') return 'glass';
      return 'metal';
    },
  },
};
</script>
<style lang="scss" scoped>
.card-infos {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .material-type-img {
    width: 150px;
  }

  .card-title {
    font-size: 1.5em;
    font-weight: 600;
  }

  .info-offer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .offered-value {
      font-size: 1em;
      color: $color_gray;
    }
  }
}
</style>
